// import { isMobile } from '@/utils'

export default {
  address: '接待會館：桃園市桃園區幸福路72號1樓',
  // isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.157201867715!2d121.30045401551043!3d25.02873864465524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681fc944eea53d%3A0x2691248ffa27da2a!2zMzMw5qGD5Zut5biC5qGD5ZyS5Y2A5bm456aP6LevNzLomZ8x!5e0!3m2!1szh-TW!2stw!4v1650006207936!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/cCM4ESCattgNrGLC8',
  phone: '03-325-7555',
  fbLink:
    'https://www.facebook.com/築億海格-105185948194625/',
  fbMessage: 'https://m.me/105185948194625/',
  caseName: '築億海格',
  houseInfos: [
    ['投資興建', '築億建設股份有限公司'],
    ['建築設計', '林永發建築師事務所'],
    ['工程營造', '興澤營造'],
    ['基地面積', '586.43坪'],
    ['公設比例', '34%'],
    ['樓層規劃', '15層/B4層'],
    // ['公設比例', '32.5%'],
    // ['樓層規劃', '地上15層，地下6層'],
    // ['基地面積', '846.66坪'],
    // ['建照號碼', '102股建字第00258號等1個'],
  ],
  gtmCode: ['K5CDGVJ','NHFGNRX'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '眼鑑為真 敬邀體驗' ,
    subTitle: '若想了解建案資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！'
  },
}
